<template>
  <el-form-item class="logRadio" label="Вход по:">
    <el-radio v-model="radio" label="Телефон" @change="handleChange" />
    <el-radio v-model="radio" label="Логин" @change="handleChange" />
  </el-form-item>
</template>

<script>
export default {
  name: 'IqLogRadio',
  data() {
    return {
      radio: 'Телефон',
    }
  },
  methods: {
    handleChange(event) {
      this.$emit('input', event)
    },
  },
}
</script>

<style lang="sass" scoped>
.logRadio
  margin-bottom: 10px
  ::v-deep .el-form-item__label
    padding: 0
    font-size: inherit
    line-height: 14px
    color: $color-black
  ::v-deep .el-form-item__content .el-radio__input.is-checked .el-radio__inner
      border-color: $color-blue-primary
      background-color: $color-blue-primary
  ::v-deep .el-form-item__content .el-radio__input.is-checked+.el-radio__label
    color: $color-blue-primary
</style>
